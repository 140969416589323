import React, { useState, useRef, useEffect } from 'react';
import Board from "../Board/Board.js";
import "./Game.scss";

/***************************************************************/
const Game = (input) => {
    const [appListenerId, setAppListenerId] = useState(null);

    /***************************************************************/
    useEffect(() => {
        const tempId = input.appModel.game.registerListener((propsChanged) => {
        });
        setAppListenerId(tempId);

        return function cleanup() {
            input.appModel.game.unregisterListener(appListenerId);
            setAppListenerId(null);
        }
    }, []);
    /***************************************************************/
    return (<div className="game-container">
        <h1 className="logo">Olivia's Matching Game</h1>
        <Board
            gameModel={input.appModel.game}
            numCards={input.numCards}
            width={input.w}
            height={input.h} />
    </div>
    );
}
/***************************************************************/
export default Game;
/***************************************************************/
