import CardModel from "../Card/CardModel.js";

/***************************************************************/
class GameModel {
    constructor() {
        this.cardsFlipped = [];
        this.listeners = [];
        this.cards = [];
        this.matches = 0;
        this.won = false;
        this.app = null;
        this.playAgain = false; 
        this.cardInventory = {};
    }
    /***************************************************************/
    setApp(app) {
        this.app = app; 
        app.setGame(this);
    }
    /***************************************************************/
    flipCard(c) {
        const card = this.getCard(c.id);
        if (!card.flipped && this.cardsFlipped.length < 2) {
            this.cardsFlipped.push(card);
            card.flip();

            const propsChanged = "cardInventory[" + c.id + "].flipped";
            this.notifyListeners(propsChanged);

            // Wait a little while and then determine if the pair flipped is a match
            setTimeout(() => {
                if (this.cardsFlipped.length === 2) {
                    let c1 = this.cardsFlipped[0]; // first card flipped
                    let c2 = this.cardsFlipped[1]; // second card flipped
                    if (c1.isMatch(c2)) {
                        const c1PropsChanged = "cardInventory[" + c1.id + "].matchFound";
                        const c2PropsChanged = "cardInventory[" + c2.id + "].matchFound";

                        this.matches += 2;
                        this.notifyListeners(c1PropsChanged);
                        this.notifyListeners(c2PropsChanged);
                    }
                    else {
                        const c1PropsChanged = "cardInventory[" + c1.id + "].flipped";
                        const c2PropsChanged = "cardInventory[" + c2.id + "].flipped";
                        c2.flip();
                        c1.flip();
                        this.notifyListeners(c1PropsChanged);
                        this.notifyListeners(c2PropsChanged);
                    }

                    // empty the flipped cards 
                    this.cardsFlipped = [];
                    if (this.hasWon()) {
                        this.won = true;
                        this.app.notifyListeners("game.won");
                    }
                }
            }, 2000);
        }
    }
    /***************************************************************/
    setCards(cards) {
        if (this.cards.length === 0) {
            this.cards = cards.map((c, i) => {
                this.cardInventory[c.id] = i;
                return new CardModel(c);
            });
        }
    }
    /***************************************************************/
    doPlayAgain() {
       // this.playAgain = true; 
        //this.notifyListeners("playAgain");
        for (let i = 0; i < this.cards.length; i++){
            // resetting each card
            const c = this.cards[i];
            c.matchFound = false;
            c.flipped = false; 
            const matchFoundProp = "cardInventory[" + c.id + "].matchFound";
            const flippedProp = "cardInventory[" + c.id + "].flipped";
            this.notifyListeners(matchFoundProp);
            this.notifyListeners(flippedProp);
        }
        this.reset();
    }
    /***************************************************************/
    reset() {
        this.cardsFlipped = [];
        this.cards = [];
        this.matches = 0;
        this.won = false;
        this.playAgain = false;
        this.cardInventory = {};

        this.notifyListeners("cards");
    }
    /***************************************************************/
    hasWon() {
        this.won = this.matches === this.cards.length; 
        return this.won; 
    }
    /***************************************************************/
    getCard(id) {
        return this.cards[this.cardInventory[id]];
    }
    /***************************************************************/
    getCards() {
        return this.cards;
    }
    /***************************************************************/
    cardsMade() {
        return this.cards.length !== 0;
    }
    /***************************************************************/
    unregisterListener(id) {
        if (id >= 0 && id < this.listeners.length) {
            this.listeners[id] = null;
        }
    }
    /***************************************************************/
    registerListener(func) {
        this.listeners.push(func);
        return this.listeners.length - 1;
    }
    /***************************************************************/
    notifyListeners(propsChanged) {
        for (let i = 0; i < this.listeners.length; i++) {
            const listener = this.listeners[i];
            if (listener !== null && listener !== undefined) {
                listener(propsChanged);
            }
        }
    }
};
/***************************************************************/

export default GameModel;