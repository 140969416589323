import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Routes, useParams } from "react-router-dom";
import Game from "./components/Game/Game.js";
import AppModel from "./AppModel.js";
import YouWon from "./components/YouWon/YouWon.js";
import './App.css';

const app_model = new AppModel();

const GameWrapper = (input) => {
  const { numCards } = useParams();
  const DEFAULT_NUM = 12; 

  const determineNumCards = () => {
    if (numCards === undefined || numCards === null) return input.numCards;
    const validNums = ["4", "6", "8", "10", "12"];
    for (let i = 0; i < validNums.length; i++) {
      const num = validNums[i];
      // if the number of cards is ever valid, return that number
      if (numCards === num) return numCards;
    }

    return DEFAULT_NUM;
  };

  return (
    <div className="App">
      <Game
        appModel={app_model}
        numCards={determineNumCards()} />
      <YouWon
        appModel={app_model}
      />
    </div>
  );
};

function App() {
  return (
    <Routes>
      <Route path="/" element={<GameWrapper numCards={12} />} />
      <Route path="/numCards/:numCards" element={<GameWrapper />} />
    </Routes>
  );
};


export default App;