class CardModel {
    constructor(c) {
        this.flipped = false;
        this.matchFound = false;
        this.color = c.color;
        this.id = c.id;
    }
    flip() {
        this.flipped = !this.flipped;
    }
    isMatch(c2) {
        if (this.color === c2.color) {
            this.matchFound = true;
            c2.matchFound = true;
            return true;
        }

        return false;
    }
    setMatchFound(mf) {
        this.matchFound = mf;
    }

    getFlipped() {
        return this.flipped;
    }

    getMatchFound() {
        return this.matchFound;
    }
}

export default CardModel; 