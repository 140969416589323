import React, { useState, useRef, useEffect } from 'react';
import Card from "../Card/Card.js";
import utils from "../../utils.js";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import "./Board.scss";

/***************************************************************/
const Board = (input) => {
    const [cards, setCards] = useState([]);
    const [appListenerId, setAppListenerId] = useState(null);

    /***************************************************************/
    useEffect(() => {
        setCards(makeCards());

        /* Register listener */
        const tempId = input.gameModel.registerListener((propsChanged) => {
            // also listen to changes on the cards
            if (propsChanged === "cards") {
                setCards(makeCards());
            }
        });
        setAppListenerId(tempId);


        return function cleanup() {
            input.gameModel.unregisterListener(appListenerId);
            setAppListenerId(null);
        }
    }, []);

    useEffect(() => {
      //  console.log("Cards value updated");
    }, [cards]);
    /***************************************************************/
    const makeCard = (c) => {
        return (<Card
            cardId={c.id}
            gameModel={input.gameModel}
            color={c} />);
    };
    /***************************************************************/
    const makeCards = () => {
        let content = [];
        if (!input.gameModel.cardsMade()) {

            //const n = (input.width * input.height) / 2;
            const n = input.numCards / 2;
            let j = 0;
            for (let i = 0; i < n; i++) {
                const color = { "color": utils.randomColor(), "id": j };
                j++;
                const dup = { "color": color.color, "id": j };
                content.push(color);
                content.push(dup);
                j++;
            }


            input.gameModel.setCards(content);
        }
        else {
            content = input.gameModel.getCards();
        }

        const listItems = utils.shuffle(content.map((c, i) => <Grid xs={4} key={i}>{makeCard(c)}</Grid>));
        return (<Grid container spacing={2}>{listItems}</Grid>);
    }
    /***************************************************************/
    return (
        <Box
            className="board"
            sx={{ flexGrow: 1 }}>
            {cards}
        </Box>
    );
}
/***************************************************************/
export default Board;
/***************************************************************/