import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import "./YouWon.scss";

export default function YouWon(input) {
    const [open, setOpen] = React.useState(false);
    const [appListenerId, setAppListenerId] = useState(null);

    useEffect(() => {
        const tempId = input.appModel.registerListener((propsChanged) => {
            if (propsChanged === "game.won") {
                setOpen(true);
            }
        });
        setAppListenerId(tempId);

        return function cleanup() {
            input.appModel.unregisterListener(appListenerId);
            setAppListenerId(null);
        }
    }, []);

    const handlePlayAgain = () => {
        input.appModel.game.doPlayAgain();
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="you-won-header">
                    {"Winner!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className="you-won-content">
                        <b>Good job! You matched all the cards!</b> Would you like to play this game again?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePlayAgain} autoFocus className="you-won-buttons">Yes</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
